export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~4],
		"/(footer)/ai": [9,[3]],
		"/api/TEST_ONLY/oauth/authorize": [17],
		"/(footer)/blog": [~10,[3]],
		"/(footer)/blog/code-review-acronyms-lgtm-nit": [~12,[3]],
		"/(footer)/blog/startup-viability-score": [~13,[3]],
		"/(footer)/blog/[slug]": [~11,[3]],
		"/(footer)/docs/commands": [14,[3]],
		"/(footer)/docs/on-premise-setup-gitlab": [~15,[3]],
		"/(footer)/imprint": [16,[3]],
		"/(app)/login": [~5,[2]],
		"/(app)/logs": [~6,[2]],
		"/(app)/review": [~7,[2]],
		"/(app)/review/[reviewId]": [~8,[2]],
		"/stats": [~18]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';